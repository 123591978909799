/* home-reducers.js */

const HomeReducer = function(
	home = {},
	action = {}
): Immutable.Map<string, any> {
	switch (action.type) {
		case "UPDATE_AD_UNITS":
			var _home = home;

			return _home;

		default:
			return home;
	}
};

export default HomeReducer;
