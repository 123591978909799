/** External Module Dependencies **/
import React from "react";
import Loadable from "react-loadable";

function Loading() {
    return (
        <div className="tk-loading"></div>
    );
}


const Home = Loadable({
    loader: () => import("./components/home/provider/home-provider"),
    loading: Loading,
});

const Telegram = Loadable({
    loader: () => import("./components/telegram/provider/telegram-provider"),
    loading: Loading,
});

const routes = [
    { path: "/", exact: true, name: "Home", component: Home },
    { path: "/telegram", exact: true, name: "Telegram", component: Telegram },
];

export default routes;
