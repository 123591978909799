/* App.js */

/** External Module Dependencies **/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo } from '@fortawesome/free-solid-svg-icons'

/** Global Module Dependencies **/
import routes from "./routes";

import "./App.css";

library.add(faIgloo);

class App extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="App">
					<Switch>
						{routes.map((route, idx) => {
							return route.component ? (
								<Route
									key={idx}
									path={route.path}
									exact={route.exact}
									name={route.name}
									render={(props) => (
										<route.component {...props} />
									)}
								/>
							) : null;
						})}
					</Switch>
				</div>
                <div id="ViewportWarning"></div>
			</React.Fragment>
		);
	}
}

export default App;
