/* reducer.js */

/** External Module Dependencies **/
import { combineReducers } from "redux";

/** Internal Module Dependencies **/
import home from "./../components/home/reducer/home-reducer";

export default combineReducers({
	home,
});
